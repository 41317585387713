<template >
  <b-container fluid>
    <loading :active.sync="isWorkingClient" :is-full-page="true"></loading>
    <b-row align-h="center"><h4>Administradores</h4></b-row>
    <b-table responsive striped small sticky-header :items="administradores" :fields="fieldsTableAdministradores" class="table-style">
      <template v-slot:cell(acciones)="row">
        <b-button variant="success" size="sm" @click="showPanelConfigAdmin(row.item.idAdmin)">Revisar</b-button>
      </template>
    </b-table>
    <!----- modals ------>
    <b-modal title="Actualizar Información de Administrador" v-model="isShowModalConfigAdmin" size="md" hide-footer>
      <h5>{{modelAdminConfig.username}}</h5>
      <b-card>
        Estatus:
        <b-form-checkbox v-model="modelAdminConfig.status" value="ACTIVO" unchecked-value="INACTIVO">Activo</b-form-checkbox><br>
        
        Nombre de usuario:
        <b-form-input :state="!$v.modelAdminConfig.username.$invalid" type="text" style="text-transform: uppercase" class="input-pass-admin" v-model.trim="modelAdminConfig.username" placeholder="Nombre de usuario"></b-form-input>
        <div class="error" v-if="!$v.modelAdminConfig.username.minLength">El nombre es demasiado corto</div>
        <div class="error" v-if="!$v.modelAdminConfig.username.maxLength">El nombre es demasiado largo</div>
        <div class="error" v-if="!$v.modelAdminConfig.username.required">Debe ingresar un nombre de usuario</div><br>
        
        Nombre(s) y Apellidos:
        <b-form-input :state="!$v.modelAdminConfig.nombre.$invalid" type="text" style="text-transform: uppercase" class="input-pass-admin" v-model.trim="modelAdminConfig.nombre" placeholder="Nombre(s) y apellidos"></b-form-input>
        <div class="error" v-if="!$v.modelAdminConfig.nombre.minLength">El nombre es demasiado corto</div>
        <div class="error" v-if="!$v.modelAdminConfig.nombre.maxLength">El nombre es demasiado largo</div>
        <div class="error" v-if="!$v.modelAdminConfig.nombre.required">Debe ingresar el nombre y apellidos</div><br>
        
        Curp:
        <b-form-input :state="!$v.modelAdminConfig.curp.$invalid" type="text" style="text-transform: uppercase" class="input-pass-admin" v-model.trim="modelAdminConfig.curp" placeholder="Curp"></b-form-input>
        <div class="error" v-if="!$v.modelAdminConfig.curp.minLength">El curp es demasiado corto</div>
        <div class="error" v-if="!$v.modelAdminConfig.curp.maxLength">El curp es demasiado largo</div>
        <div class="error" v-if="!$v.modelAdminConfig.curp.required">Debe ingresar un curp</div><br>
        
        Correo:
        <b-form-input :state="!$v.modelAdminConfig.correo.$invalid" type="email" class="input-pass-admin" v-model.trim="modelAdminConfig.correo" placeholder="Correo"></b-form-input>
        <div class="error" v-if="!$v.modelAdminConfig.correo.email">Debe ingresar un correo válido</div>
        <div class="error" v-if="!$v.modelAdminConfig.correo.maxLength">El correo es demasiado largo</div>
        <div class="error" v-if="!$v.modelAdminConfig.correo.required">Debe ingresar un correo electrónico</div><br>
        
        Teléfono:
        <b-form-input :state="!$v.modelAdminConfig.telefono.$invalid" type="tel" class="input-pass-admin" v-model.trim="modelAdminConfig.telefono" placeholder="Teléfono"></b-form-input>
        <div class="error" v-if="!$v.modelAdminConfig.telefono.numeric">Debe ingresar un número válido</div>
        <div class="error" v-if="!$v.modelAdminConfig.telefono.maxLength">El teléfono es demasiado largo</div>
        <div class="error" v-if="!$v.modelAdminConfig.telefono.minLength">El teléfono es muy corto</div>
        <div class="error" v-if="!$v.modelAdminConfig.telefono.required">Debe ingresar un número de teléfono</div><br>
        
        <b-button :disabled="$v.$invalid" block variant="success" @click="guardarCambiosAdmin()">Guardar Cambios</b-button>
        <div class="error" v-if="$v.$invalid">Algunos datos estan incompletos.</div>
      </b-card>
    </b-modal>
  </b-container>
</template>

<script>
  import { minLength, maxLength, required, requiredIf, email, numeric} from 'vuelidate/lib/validators';
  import { mapState} from 'vuex';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { actualizarInfoAdmin } from '@/axios-tools';

  export default {
    computed: mapState({
      token: state => state.client.token,
      isWorkingClient: state => state.client.isWorkingClient,
      administradores: state => state.client.administradores
    }),
    components: {
      Loading
    },
    data() {
      return {
        fieldsTableAdministradores:[{key: 'username',label: 'Usuario'},{key: 'acciones',label: 'Acciones'}],
        isShowModalConfigAdmin:false,
        modelAdminConfig:{
          username:"",
          nombre:"",
          curp:"",
          status:"",
          pass:"",
          correo:"",
          telefono:"",
          idAdmin:null
        }
      }
    },
    methods: {
      guardarCambiosAdmin(){
        actualizarInfoAdmin(this.$store, this.modelAdminConfig, this.token, this.$router);
      },
      showPanelConfigAdmin(idAdmin){
        var idx = this.getIdxAdministradores(idAdmin);
        this.modelAdminConfig.username=this.administradores[idx].username;
        this.modelAdminConfig.nombre=this.administradores[idx].nombre;
        this.modelAdminConfig.curp=this.administradores[idx].curp;
        this.modelAdminConfig.idAdmin=this.administradores[idx].idAdmin;
        this.modelAdminConfig.pass=this.administradores[idx].pass;
        this.modelAdminConfig.status=this.administradores[idx].status;
        this.modelAdminConfig.correo=this.administradores[idx].correo;
        this.modelAdminConfig.telefono=this.administradores[idx].telefono;
        this.isShowModalConfigAdmin=true;
      },
      getIdxAdministradores(idAdmin){
        for(var idx=0; idx<this.administradores.length; idx++){
          if(this.administradores[idx].idAdmin==idAdmin){
            return idx;
          }
        }
        return -1;
      }
    },
    validations: {
        modelAdminConfig:{
          username:{
            required,
            minLength: minLength(3),
            maxLength: maxLength(15)
          },
          nombre:{
            required,
            minLength: minLength(3),
            maxLength: maxLength(200)
          },
          curp:{
            required,
            minLength: minLength(18),
            maxLength: maxLength(18)
          },
          correo:{
            required : requiredIf(function () {
                  return this.modelAdminConfig.status == 'ACTIVO'
                }),
            email,
            maxLength: maxLength(50)
          },
          telefono:{
            required : requiredIf(function () {
                  return this.modelAdminConfig.status == 'ACTIVO'
                }),
            numeric,
            minLength: minLength(10),
            maxLength: maxLength(10)
          }
        }
    }
  }
</script>
<style>
.input-pass-admin{
  margin-bottom:5px;
}
div.error{
  color: red;
}
</style>